import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getProfile () {

  return axios({
    url: 'v1/admin/env/profile',
    method: 'get'
  })
}

export function getProfileClasses (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/classes${queries}`,
    method: 'get'
  })
}
export function getProfileClassesPrice (filters = []) {
  let queries = makeQuery(0, filters)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/total-classes${queries}`,
    method: 'get'
  })
}

export function editProfile (payment) {

  return axios.patch(`v1/admin/env/profile/${payment.id}`, payment)
}
